import React from 'react'
import WhoWeAre from './WhoWeAre'
import CompanyDetails from './CompnayDetails'

function MainAboutBG() {
  return (
    <div className='main_padding' >
        <WhoWeAre/>
        <CompanyDetails/>
    </div>
  )
}

export default MainAboutBG